import * as actionTypes from "./constant";
import { put, takeEvery, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { push } from "connected-react-router";
import { appConfig } from "../../config";

function* commonFunction(value) {
  const userToken = localStorage.getItem("userToken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: value.body ? value.body : null,
    });
    
    if (!response.ok) {
      if (response.status === 401) {
        yield localStorage.removeItem("userToken");
        yield localStorage.removeItem("role");
        yield localStorage.removeItem("userData");
        yield localStorage.removeItem("userFilters");
        yield localStorage.removeItem("partyFilters");
        yield put(push("/login"));
      }
      throw response;
    } else {
      if (value.successAction === actionTypes.EXPORT_REPORT_DATA_SUCCESS_ACTION) {
        const contentType = response.headers.get("Content-Type");
        if (contentType && (contentType.includes("application/vnd.ms-excel") || contentType.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") || contentType.includes("text/csv"))) {
          const blobResponse = yield response.blob();
          if (blobResponse instanceof Blob) {
            return blobResponse; 
          } 
        } else {
          if (value.toastMessage) {
            yield toast.success();
          }
        }
      }
      const resJSON = yield response.json();
  
      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });
      if (value.successAction) {
        if (value.toastMessage) {
          yield toast.success(value.toastMessage);
        }
      }
      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
      return resJSON;
    }
  } catch (error) {
    yield put({ type: `${value.failAction}`, error: error });
    if (value.failAction) {
      if (value.toastErrorMessage) {
        yield toast.error(value.toastErrorMessage);
      }
    }
  }
}

function* fetchReport(value) {
  let user = localStorage.getItem("role");
  let url = "";
  if (user === "regionHead") {
    url = "verification/region/pincode";
  }else if(user === 'circleHead'){
    url = "verification/circle/pincode";
  } else {
    url = "verification/all";
  }
  try {
    let params = {
      api: `${appConfig.ip}/${url}`,
      method: "GET",
      successAction: actionTypes.FETCH_REPORT_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_REPORT_FAIL_ACTION,
    };

    yield call(commonFunction, params);
  } catch (e) {}
}

function* updateCustomerData(value) {
  let customersId = value?.value?.assignItems || [];
  
  let assignedTo = value?.value?.value?.assignTo || "";
    
  let assignedName = value?.value?.value?.assignedName || "";
      
  let type = value?.value?.type || "";
  let toastmsg = '';
  let cust = "";
  let url = "";
  let data = {};

  if (customersId.length === 1) {
    if (type === "Assign") {
      cust = customersId[0];
      toastmsg =  "Successfully assigned";
      url = `/verification/updateAssignedCustomer/${cust}`;
      data = {
        assignedTo: assignedTo,
        assignedName: assignedName,
      };
    } else {
      toastmsg =  "Successfully unassigned";
      data= {
        assignedTo:"",
        assignedName:''
      }
      url=`/verification/customers/deallocate/${customersId[0]}`
    }
  } else {
    if (type === "Assign") {
      toastmsg =  "Successfully assigned";
      data = {
        customerIds: customersId,
        assignedTo: assignedTo,
        assignedName: assignedName,
      };
      url = `/verification/updateAllAssignedCustomers`;
    } else {
      toastmsg =  "Successfully unassigned";
      data= {
        customerIds:customersId,
        assignedTo:"",
        assignedName:''
      }
      url='/verification/customers/bulk/deallocate'
    }
  }
  
  try {
    let params = {
      api: `${appConfig.ip}${url}`,
      method: "PATCH",
      successAction: actionTypes.UPDATE_PROJECTS_DATA_SUCCESS_ACTION,
      failAction: actionTypes.UPDATE_PROJECTS_DATA_FAIL_ACTION,
      body: JSON.stringify(data),
      toastMessage: toastmsg,
      toastErrorMessage: "Something went wrong, Try again",
    };

    yield call(commonFunction, params);
  } catch (error) {
    // console.error('Error in updateCustomerData:', error);
  }
}

function* fetchCustomerById(value) {
  let id = value.value.id;
  try {
    let params = {
      api: `${appConfig.ip}/verification/customers/${id}`,
      method: "GET",
      successAction: actionTypes.FETCH_CUSTOMERBYID_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_CUSTOMERBYID_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* fetchVerification(value) {
  
  let id = value?.value?.id || "";
  let page = value && value.value.page ? value.value.page : 1;
  try {
    let params = {
      api: `${appConfig.ip}/verification/linkedTo/${id}?page=${page}&limit=20`,
      method: "GET",
      successAction: actionTypes.FETCH_VERIFICATION_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_VERIFICATION_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (e) {}
}

function* fetchActivityReport(value) {
  let user = localStorage.getItem("role");
  let url = "";
  let toDate= value?.value?.toDate || '';
  let fromDate = value?.value?.fromDate || '';
  let page = value?.value?.page || 1

  if (user === "regionHead") {
    if((toDate !== '' && fromDate!== '')) {
        url = `verification/region?startDate=${fromDate}&endDate=${toDate}&page=${page}&limit=20`;
    }
  } else if(user === 'circleHead'){
    if((toDate !== '' && fromDate!== '')) {
      url = `verification/circle?startDate=${fromDate}&endDate=${toDate}&page=${page}&limit=20`;
  }
  } else {
    if((toDate !== '' && fromDate!== '') ) {
        url = `verification/all?startDate=${fromDate}&endDate=${toDate}&page=${page}&limit=20`;
    } 
  }
  try {
    let params = {
      api: `${appConfig.ip}/${url}`,
      method: "GET",
      successAction: actionTypes.FETCH_ALL_REPORT_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_ALL_REPORT_FAIL_ACTION,
    };

    yield call(commonFunction, params);
   
  } catch (e) {}
}

function* fetchAllActivityReport(value) {
  
  let user = localStorage.getItem("role");
  let url = "";
  let toDate= value?.value?.toDate || '';
  let fromDate = value?.value?.fromDate || '';

  if (user === "regionHead") {
    if((toDate !== '' && fromDate!== '')) {
        url = `verification/region?startDate=${fromDate}&endDate=${toDate}`;
    } 
  } else if(user === 'circleHead'){
    if((toDate !== '' && fromDate!== '')) {
      url = `verification/circle?startDate=${fromDate}&endDate=${toDate}`;
  } 
  } else {
    if((toDate !== '' && fromDate!== '') ) {
        url = `verification/all?startDate=${fromDate}&endDate=${toDate}`;
    } 
  }
  try {
    let params = {
      api: `${appConfig.ip}/${url}`,
      method: "GET",
      successAction: actionTypes.FETCH_ALL_ACTIVITY_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_ALL_ACTIVITY_FAIL_ACTION,
    };

    yield call(commonFunction, params);
   
  } catch (e) {}
}

function* fetchVerifiedReport(value) {
  let user = localStorage.getItem("role");
  let toDate= value?.value?.toDate || '';
  let fromDate = value?.value?.fromDate || '';
  let page = value?.value?.page || 1;

  let url = "";
  if (user === "regionHead") {    
    if((toDate !== '' && fromDate!== '')) {
      url = `verification/region?visitStatus=Verification&startDate=${fromDate}&endDate=${toDate}&page=${page}&limit=20`;
    }
  } else if( user === 'circleHead'){
    if((toDate !== '' && fromDate!== '')) {
      url = `verification/circle?visitStatus=Verification&startDate=${fromDate}&endDate=${toDate}&page=${page}&limit=20`;
    }
  } else {
    if((toDate !== '' && fromDate!== '') ) {
      url = `verification/all?visitStatus=Verification&startDate=${fromDate}&endDate=${toDate}&page=${page}&limit=20`;
    } 
  }

  try {
    let params = {
      api: `${appConfig.ip}/${url}`,
      method: "GET",
      successAction: actionTypes.FETCH_VERIFIED_REPORT_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_VERIFIED_REPORT_FAIL_ACTION,
    };

    yield call(commonFunction, params);
  } catch (e) {}
}

function* fetchAllVerifiedReport(value) {
  let user = localStorage.getItem("role");
  let toDate= value?.value?.toDate || '';
  let fromDate = value?.value?.fromDate || '';

  let url = "";
  if (user === "regionHead") {
    if((toDate !== '' && fromDate!== '')) {
      url = `verification/region?visitStatus=Verification&startDate=${fromDate}&endDate=${toDate}`;
    } 
  
  } else if(user === 'circleHead'){
    if((toDate !== '' && fromDate!== '')) {
      url = `verification/circle?visitStatus=Verification&startDate=${fromDate}&endDate=${toDate}`;
    } 
  } else {
    if((toDate !== '' && fromDate!== '') ) {
      url = `verification/all?visitStatus=Verification&startDate=${fromDate}&endDate=${toDate}`;
    } 
   
  }

  try {
    let params = {
      api: `${appConfig.ip}/${url}`,
      method: "GET",
      successAction: actionTypes.FETCH_ALL_VERIFIED_ACTIVITY_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_ALL_VERIFIED_ACTIVITY_FAIL_ACTION,
    };

   yield call(commonFunction, params);
   
  } catch (e) {}
}

function* fetchCustomerReport(value) {
  const role = localStorage.getItem("role");
  let stateItem = value?.value?.stateItem || "";
  let distVal = value?.value?.distVal || "";
  let searchItem = value?.value?.searchItem || "";
  let status = value?.value?.status || "";
  let allocated = value?.value?.allocated || "";
  // let page = value?.value?.page || 1;
  let pincode = value?.value?.pincode|| "";
  let assignedTo = value?.value?.assignedTo
  
  let endPoint = "";
  if (role === "admin") {
    if (stateItem || distVal || searchItem || allocated || status || assignedTo || pincode) {
      if (allocated === "Allocated") {
          if(status === "Verified"){
            endPoint = `verification/allocated?isVerified=true&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
          } else if (status === "Not Verified") {            
            endPoint = `verification/allocated?isVerified=false&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
          } else {
            endPoint = `verification/allocated?state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
          }

      } else if (allocated === "Unallocated") {
        if(status === "Verified"){
          endPoint = `verification/nonAllocated?isVerified=tru&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
        } else if (status === "Not Verified") {
          endPoint = `verification/nonAllocated?isVerified=false&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
        } else {
          endPoint = `verification/nonAllocated?state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
        }
      } else if (status === "Verified") {
        endPoint = `verification/customersByName?isVerified=true&state=${stateItem}&district=${distVal}&search=${searchItem}&assignedTo=${assignedTo}&pin=${pincode}`;
      } else if (status === "Not Verified") {
        endPoint = `verification/customersByName?isVerified=false&state=${stateItem}&district=${distVal}&search=${searchItem}&assignedTo=${assignedTo}&pin=${pincode}`;
      } else {
        endPoint = `verification/customersByName?state=${stateItem}&district=${distVal}&search=${searchItem}&assignedTo=${assignedTo}&pin=${pincode}`;
      }
    } else {
      endPoint = `verification/customersByName?`;
    }
  } else if(role === 'circleHead') {
    if (stateItem || distVal || searchItem || allocated || status || assignedTo || pincode) {
      if (allocated === "Allocated") {
        if(status === "Verified"){
          endPoint = `verification/allocation/circle?isVerified=true&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
        } else if (status === "Not Verified") {
          endPoint = `verification/allocation/circle?isVerified=false&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
        } else {
          endPoint = `verification/allocation/circle?&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
        }

      } else if (allocated === "Unallocated") {
          if(status === "Verified"){
            endPoint = `verification/nonAllocation/circle?isVerified=true&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
          } else if (status === "Not Verified") {
            endPoint = `verification/nonAllocation/circle?isVerified=false&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
          } else {
            endPoint = `verification/nonAllocation/circle?state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
          }
      } else if (status === "Verified") {
        endPoint = `verification/getCustomers/circle?isVerified=true&state=${stateItem}&district=${distVal}&search=${searchItem}&assignedTo=${assignedTo}&pin=${pincode}`;
      } else if (status === "Not Verified") {
        endPoint = `verification/getCustomers/circle?isVerified=false&state=${stateItem}&district=${distVal}&search=${searchItem}&assignedTo=${assignedTo}&pin=${pincode}`;
      } else {
        endPoint = `verification/getCustomers/circle?state=${stateItem}&district=${distVal}&search=${searchItem}&assignedTo=${assignedTo}&pin=${pincode}`;
      }
    } else {
        endPoint = `verification/getCustomers/circle`;
      }
    } else if(role === 'regionHead') {
      if (stateItem || distVal || searchItem || allocated || status || assignedTo || pincode) {
        if (allocated === "Allocated") {
          if(status === "Verified"){
            endPoint = `verification/allocation/region?isVerified=true&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
          } else if (status === "Not Verified") {
            endPoint = `verification/allocation/region?isVerified=false&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
          } else {
            endPoint = `verification/allocation/region?state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
          }
  
        } else if (allocated === "Unallocated") {
            if(status === "Verified"){
              endPoint = `verification/nonAllocation/region?isVerified=true&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
            } else if (status === "Not Verified") {
              endPoint = `verification/nonAllocation/region?isVerified=false&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
            } else {
              endPoint = `verification/nonAllocation/region?state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
            }
        } else if (status === "Verified") {
          endPoint = `verification/getCustomers/region?isVerified=true&state=${stateItem}&district=${distVal}&search=${searchItem}&assignedTo=${assignedTo}&pin=${pincode}`;
        } else if (status === "Not Verified") {
          endPoint = `verification/getCustomers/region?isVerified=false&state=${stateItem}&district=${distVal}&search=${searchItem}&assignedTo=${assignedTo}&pin=${pincode}`;
        } else {
          endPoint = `verification/getCustomers/region?state=${stateItem}&district=${distVal}&search=${searchItem}&assignedTo=${assignedTo}&pin=${pincode}`;
        }
      } else {
          endPoint = `verification/getCustomers/region`;
        }
    }

  try {
    let params = {
      api: `${appConfig.ip}/${endPoint}`,
      method: "GET",
      failAction: actionTypes.FETCH_CUSTOMER_REPORT_DATA_SUCCESS_ACTION,
      successAction: actionTypes.FETCH_CUSTOMER_REPORT_DATA_FAIL_ACTION,
    };
   yield call(commonFunction, params); 
   
  } catch (error) {
  }
}

function chunkArray(array, chunkSize) {
  let chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

function* exportData(value) {
  
  const format = value?.value?.format;
  const verification = value?.value?.verification || [];
  const modalType = value?.value?.modalType ;
  const chunkSize = 10000; 
  const customerChunks = chunkArray(verification, chunkSize);

  try {
    const allBlobs = [];

    for (const chunk of customerChunks) {
      const data = { verification: chunk };
      const urls = `/verification/export?format=${format}`;

      let params = {
        api: `${appConfig.ip}${urls}`,
        method: "POST",
        successAction: actionTypes.EXPORT_REPORT_DATA_SUCCESS_ACTION,
        failAction: actionTypes.EXPORT_REPORT_DATA_FAIL_ACTION,
        body: JSON.stringify(data),
        toastErrorMessage: "Failed to export data",
      };

      const blobResponse = yield call(commonFunction, params);

      if (blobResponse instanceof Blob) {
        allBlobs.push(blobResponse); 
      }
    }

    const combinedBlob = new Blob(allBlobs, { type: allBlobs[0].type });

    const url = window.URL.createObjectURL(combinedBlob);
    const a = document.createElement("a");
    a.href = url;
   if(modalType === 'AllActivities'){
      if (format === "excel") {
        a.download = `Activity_Report_${Date.now()}.xlsx`;
      } else {
        a.download = `Activity_Report_${Date.now()}.csv`;
      }
   } else if(modalType === 'Verification'){
      if (format === "excel") {
        a.download = `Verification_Report_${Date.now()}.xlsx`;
      } else {
        a.download = `Verification_Report_${Date.now()}.csv`;
      }
   }
  
    
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);

    yield toast.success("Successfully exported  data");
    
  } catch (error) {
  }
}

export function* CustomerDetailsWatcher() {
  yield takeEvery(
    actionTypes.FETCH_VERIFICATION_INIT_ACTION,
    fetchVerification
  );
  yield takeEvery(
    actionTypes.UPDATE_PROJECTS_DATA_INIT_ACTION,
    updateCustomerData
  );
  yield takeEvery(
    actionTypes.FETCH_CUSTOMERBYID_INIT_ACTION,
    fetchCustomerById
  );

  yield takeEvery(actionTypes.FETCH_REPORT_INIT_ACTION, fetchReport);
  yield takeEvery(actionTypes.FETCH_ALL_REPORT_INIT_ACTION, fetchActivityReport);
  yield takeEvery(
    actionTypes.FETCH_VERIFIED_REPORT_INIT_ACTION,
    fetchVerifiedReport
  );
  yield takeEvery(actionTypes.FETCH_CUSTOMER_REPORT_DATA_INIT_ACTION, fetchCustomerReport)
  yield takeEvery(actionTypes.EXPORT_REPORT_DATA_INIT_ACTION, exportData)
  yield takeEvery(actionTypes.FETCH_ALL_ACTIVITY_INIT_ACTION, fetchAllActivityReport)
  yield takeEvery(actionTypes.FETCH_ALL_VERIFIED_ACTIVITY_INIT_ACTION, fetchAllVerifiedReport)
}
