import * as actionTypes from "./constant";

const initialState = {
  processing: false,
  error: false,
  emissionFactorByNoList: [],
  generalInfoList: {},
  verificationList: [],
  verificationCount: 0,
  reportData: [],
  reportCount: 0,
  activityFetchId:'',
  activityReportData:[],
  allReportCount:0,
  verifiedReportData:[],
  verifiedReportCount:0,
  customerReportData:[],
  allActivityReportData:[],
  allVerfiedReport:[],
  isLoading: true
};

export default function CustomerDetailsReducer(state = initialState, action) {

  let user = localStorage.getItem("role");
  switch (action.type) {
    case actionTypes.FETCH_REPORT_SUCCESS_ACTION:
      return {
        ...state,
        reportData: action.payload.verification,
        reportCount: action.payload.totalCount,
      };
    case actionTypes.FETCH_VERIFICATION_SUCCESS_ACTION:   
      return {
        ...state,
        verificationCount: action.payload.totalCount,
        verificationList: action.payload.details,
      };
      case actionTypes.ADD_ACTIVITYID_SUCCESS_ACTION:           
      return {
        ...state,
        activityFetchId: action.value.id,
      };
    case actionTypes.FETCH_CUSTOMERBYID_SUCCESS_ACTION:
      return {
        ...state,
        generalInfoList: {...action.payload},
      };
    case actionTypes.FETCH_ALL_REPORT_SUCCESS_ACTION:
    return {
      ...state,
      activityReportData: action.payload.verification,
      allReportCount: user !== 'admin'? action.payload.filteredCount: action.payload.totalCount,    
    };
    case actionTypes.FETCH_VERIFIED_REPORT_SUCCESS_ACTION:
    return {
      ...state,
      verifiedReportData: action.payload.verification,
      verifiedReportCount:user !== 'admin'? action.payload.filteredCount: action.payload.totalCount,
    };
    case actionTypes.FETCH_CUSTOMER_REPORT_DATA_SUCCESS_ACTION:
      
      return {
        ...state,
        customerReportData : action.payload.customers,
      };
    case actionTypes.FETCH_CUSTOMER_REPORT_DATA_INIT_ACTION:
      return {
        ...state,
        customerReportData: action.payload
      }

      case actionTypes.FETCH_ALL_ACTIVITY_SUCCESS_ACTION:
      return {
        ...state,
        allActivityReportData : action.payload.verification,
        isLoading: false
      };
      case actionTypes.FETCH_ALL_ACTIVITY_INIT_ACTION:
        return {
          ...state,
          allActivityReportData:action.payload
        }
      case actionTypes.FETCH_ALL_VERIFIED_ACTIVITY_SUCCESS_ACTION:
      
      return {
        ...state,
        allVerfiedReport : action.payload.verification,
        isLoading: false
      };
      case actionTypes.FETCH_ALL_VERIFIED_ACTIVITY_INIT_ACTION:
        return {
          ...state,
          allVerfiedReport: action.payload
        }

    default:
      return {
        ...state,
      };
  }
}
