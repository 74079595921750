import React from "react";
import { ErrorMessage, useField } from "formik";
import "./cmnStyle.css";

export const TextField = ({ label, isRequired, ...props }) => {
  const [field,] = useField(props);
  const check =
    (field.name && field.name.includes("lossRate")) ||
    (field.name && field.name.includes("quantity")) ||
    (field.name && field.name.includes("SurfaceAreaOfBuilding"));

  return (
    <div className="mb-2 " style={{ position: "relative", flex: 1 }}>
      <label htmlFor={field.name} className="label-inpt">
        {label} {isRequired && <span className="astrk">*</span>}
      </label>
      <input
        className={
          field.name === "email"
            ? `form-control shadow-none email`
            : `form-control shadow-none `
        }
        {...field}
        {...props}
        autoComplete="off"
      />

      {!check && (
        <ErrorMessage component="div" name={field.name} className="errStyle" />
      )}
    </div>
  );
};
