export const FETCH_USER_INIT_ACTION = "FETCH_USER_INIT_ACTION";
export const FETCH_USER_SUCCESS_ACTION = FETCH_USER_INIT_ACTION + "_SUCCESS";
export const FETCH_USER_FAIL_ACTION = FETCH_USER_INIT_ACTION + "_ERROR";

export const FETCH_FIELDUSER_INIT_ACTION = "FETCH_FIELDUSER_INIT_ACTION";
export const FETCH_FIELDUSER_SUCCESS_ACTION =
  FETCH_FIELDUSER_INIT_ACTION + "_SUCCESS";
export const FETCH_FIELDUSER_FAIL_ACTION =
  FETCH_FIELDUSER_INIT_ACTION + "_ERROR";

export const FETCH_USERID_INIT_ACTION = "FETCH_USERID_INIT_ACTION";
export const FETCH_USERID_SUCCESS_ACTION =
  FETCH_USERID_INIT_ACTION + "_SUCCESS";
export const FETCH_USERID_FAIL_ACTION = FETCH_USERID_INIT_ACTION + "_ERROR";

export const ADD_USER_INIT_ACTION = "ADD_USER_INIT_ACTION";
export const ADD_USER_SUCCESS_ACTION = ADD_USER_INIT_ACTION + "_SUCCESS";
export const ADD_USER_FAIL_ACTION = ADD_USER_INIT_ACTION + "_ERROR";

export const EDIT_USER_INIT_ACTION = "EDIT_USER_INIT_ACTION";
export const EDIT_USER_SUCCESS_ACTION = EDIT_USER_INIT_ACTION + "_SUCCESS";
export const EDIT_USER_FAIL_ACTION = EDIT_USER_INIT_ACTION + "_ERROR";

export const DELETE_USER_INIT_ACTION = "DELETE_USER_INIT_ACTION";
export const DELETE_USER_SUCCESS_ACTION = DELETE_USER_INIT_ACTION + "_SUCCESS";
export const DELETE_USER_FAIL_ACTION = DELETE_USER_INIT_ACTION + "_ERROR";

export const CHANGE_STATUS_USER_INIT_ACTION = "CHANGE_STATUS_USER_INIT_ACTION";
export const CHANGE_STATUS_USER_SUCCESS_ACTION =
  CHANGE_STATUS_USER_INIT_ACTION + "_SUCCESS";
export const CHANGE_STATUS_USER_FAIL_ACTION =
  CHANGE_STATUS_USER_INIT_ACTION + "_ERROR";


  export const EXPORT_USER_DATA_INIT_ACTION = "EXPORT_USER_DATA_INIT_ACTION";
  export const EXPORT_USER_DATA_SUCCESS_ACTION = EXPORT_USER_DATA_INIT_ACTION + "_SUCCESS";
  export const EXPORT_USER_DATA_FAIL_ACTION = EXPORT_USER_DATA_INIT_ACTION + "_ERROR";

  export const FETCH_USER_REPORT_INIT_ACTION = "FETCH_USER_REPORT_INIT_ACTION";
  export const FETCH_USER_REPORT_SUCCESS_ACTION = FETCH_USER_REPORT_INIT_ACTION + "_SUCCESS";
  export const FETCH_USER_REPORT_FAIL_ACTION = FETCH_USER_REPORT_INIT_ACTION + "_ERROR";

  export const FETCH_CIRCLE_NAME_INIT_ACTION = "FETCH_CIRCLE_NAME_INIT_ACTION";
  export const FETCH_CIRCLE_NAME_SUCCESS_ACTION = FETCH_CIRCLE_NAME_INIT_ACTION + "_SUCCESS";
  export const FETCH_CIRCLE_NAME_FAIL_ACTION = FETCH_CIRCLE_NAME_INIT_ACTION + "_ERROR";

  export const FETCH_REGION_NAME_INIT_ACTION = "FETCH_REGION_NAME_INIT_ACTION";
  export const FETCH_REGION_NAME_SUCCESS_ACTION = FETCH_REGION_NAME_INIT_ACTION + "_SUCCESS";
  export const FETCH_REGION_NAME_FAIL_ACTION = FETCH_REGION_NAME_INIT_ACTION + "_ERROR";

  export const FETCH_PINCODS_INIT_ACTION = "FETCH_PINCODS_INIT_ACTION";
  export const FETCH_PINCODS_SUCCESS_ACTION = FETCH_PINCODS_INIT_ACTION + "_SUCCESS";
  export const FETCH_PINCODS_FAIL_ACTION = FETCH_PINCODS_INIT_ACTION + "_ERROR";