import * as actionTypes from './constants';
import {put, takeEvery,} from 'redux-saga/effects';
import {appConfig} from '../../config';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { push } from "connected-react-router";
import { call } from 'redux-saga/effects';

function* bulkUserAdd(action) {
    const token = localStorage.getItem("userToken");
    const file = action.value.file;
    try {
    let urls ='/users/import';
      let formData = new FormData();
      formData.append("file", file);
  
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };
  
      const response = yield fetch(`${appConfig.ip}${urls}`, {
        method: "POST",
        headers: headers,
        body: formData,
      });

      if (!response.ok) {
        if (response.status === 401) {
          yield localStorage.removeItem("userToken");
          yield localStorage.removeItem("role");
          yield localStorage.removeItem("userData");
          yield localStorage.removeItem("userFilters");
          yield localStorage.removeItem("partyFilters");
          yield put(push("/login"));
        }
        yield toast.error(action.toastErrorMessage || "Failed to upload file.");
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const resJSON = yield response.json();
      
      yield put({
        type: actionTypes.BULK_USER_ADD_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success(action.toastMessage || "File uploaded successfully.");
      return resJSON;
    } catch (error) {
      yield put({ type: actionTypes.BULK_USER_ADD_FAIL_ACTION, error });
    }
  }
  

  function* fetchPincodeFile(value) {
    const token = localStorage.getItem("userToken");
  
    try {
      const apiUrl = `${appConfig.ip}/masterdata/postalCode/export`;
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };
      const response = yield fetch(`${apiUrl}`, {
        method: "POST",
        headers: headers,
      });
  
      if (!response.ok) {
        throw new Error("Failed to fetch the file");
      }
  
      const blobResponse = yield call([response, response.blob]);
  
      const url = window.URL.createObjectURL(blobResponse);
  
      const a = document.createElement("a");
      a.href = url;
      a.download = `Pincode.csv`;
  
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
  
      window.URL.revokeObjectURL(url);
  
    } catch (error) {
      yield toast.error("An error occurred while downloading");
    }
  }
  

  export function* BulkUserAddWatcher (){
    yield takeEvery(
        actionTypes.BULK_USER_ADD_INIT_ACTION, bulkUserAdd
    )
    yield takeEvery(actionTypes.FETCH_PINCODE_DETAILS_INIT_ACTION, fetchPincodeFile)
  }