import * as actionTypes from "./constant";
import { put, takeEvery, call, } from "redux-saga/effects";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { push } from "connected-react-router";

function* commonFunction(value) {
  const userToken = localStorage.getItem("userToken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: value.body ? value.body : null,
    });

    const contentType = response.headers.get("Content-Type");

    if (!response.ok) {
      if (response.status === 401) {
        yield localStorage.removeItem("userToken");
        yield localStorage.removeItem("role");
        yield localStorage.removeItem("userData");
        yield localStorage.removeItem("userFilters");
        yield localStorage.removeItem("partyFilters");
        yield put(push("/login"));
      }
      throw response;
    } else {
      if (response.status === 201 && value.successAction === actionTypes.EXPORT_USER_DATA_SUCCESS_ACTION) {
        if (contentType && (
            contentType.includes("application/vnd.ms-excel") ||
            contentType.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") ||
            contentType.includes("text/csv")
        )) {
          const blobResponse = yield response.blob();
          if (blobResponse instanceof Blob) {
            return blobResponse;
          }
        } else {
          yield toast.success(value.toastMessage || "File export successful");
        }
      } else {
        const resJSON = yield response.json();
        yield put({
          type: `${value.successAction}`,
          payload: resJSON,
        });

        if (value.toastMessage) {
          yield toast.success(value.toastMessage);
        }

        if (value.extraAction) {
          yield put({
            type: `${value.extraAction}`,
            payload: resJSON,
          });
        }
      }
    }
    return response;
  } catch (error) {
    yield put({ type: `${value.failAction}`, error: error });
    if (value.toastErrorMessage) {
      yield toast.error(value.toastErrorMessage);
    }
  }
}


function* addUser(value) {
  let userName = value?.values?.name;
  let mobileNo= value?.values?.mobileNo;
  let state = value?.values?.state;
  let district = value.values.district ? value.values.district : "";
  let role = value.values.userRole ? value.values.userRole : "fieldUser";
  let pincode = value?.values?.pincode;
  let circle = value?.values?.circleName;
  let password =value?.values?.pwdCode;
  let region= value?.values?.regionName;
  let data = {};

  if(role === 'circleHead') {
     data = {
      userName: userName,
      mobileNo: mobileNo,
      password: password,
      isActive: true,
      state: state,
      role: role,
      isDeleted: false,
      circleName: circle
    };
  } else if(role === 'regionHead'){
    data = {
      userName: userName,
      mobileNo: mobileNo,
      password: password,
      isActive: true,
      state: state,
      role: role,
      isDeleted: false,
      circleName: circle,
      regionName: region
    };
    
  } else {
     data = {
      userName: userName,
      mobileNo: mobileNo,
      isActive: true,
      state: state,
      district: district,
      role: role,
      isDeleted: false,
      pincode: pincode,
      circleName: circle,
      regionName: region
    };
  }

  try {
    let params = {
      api: `${appConfig.ip}/auth/register`,
      method: "POST",
      successAction: actionTypes.ADD_USER_SUCCESS_ACTION,
      failAction: actionTypes.ADD_USER_FAIL_ACTION,
      extraAction: actionTypes.FETCH_USER_INIT_ACTION,
      body: JSON.stringify(data),
      toastMessage: "User added successfully",
      toastErrorMessage: "Something went wrong",
    };
    yield call(commonFunction, params); 
 
  } catch (err) {}
}

function* fetchUser(value) {
  const role = localStorage.getItem("role");
  let searchVal = value?.value?.searchVal || "";
  let state = value?.value?.state || "";
  let district = value?.value?.district || "";
  let status = value?.value?.status || "";
  let page = (value.value && value.value.page) || 1;
  let pin = value?.value?.pin || '';
  let regionName = value?.value?.regionName || '';
  let circleName = value?.value?.circleName || '';
  let url = "";
  if (role === "regionHead") {
    if (state || district || searchVal || status || pin) {
      if (status === "Active") {
        url = `users/getUsers/region?state=${state}&district=${district}&isActive=true&page=${page}&limit=20&search=${searchVal}&pincode=${pin}`;
      } else if (status === "Inactive") {
        url = `users/getUsers/region?state=${state}&district=${district}&isActive=false&page=${page}&limit=20&search=${searchVal}&pincode=${pin}`;
      } else {
        url = `users/getUsers/region?state=${state}&district=${district}&page=${page}&limit=20&search=${searchVal}&pincode=${pin}`;
      }
    } else {
      url = `users/getUsers/region?page=${page}&limit=20&`;
    }
  } else if(role === 'circleHead'){
    if (state || district || searchVal || status || pin || regionName) {
      if (status === "Active") {
        url = `users/getUsers/circle?state=${state}&district=${district}&isActive=true&page=${page}&limit=20&search=${searchVal}&pincode=${pin}&regionName=${regionName}`;
      } else if (status === "Inactive") {
        url = `users/getUsers/circle?state=${state}&district=${district}&isActive=false&page=${page}&limit=20&search=${searchVal}&pincode=${pin}&regionName=${regionName}`;
      } else {
        url = `users/getUsers/circle?state=${state}&district=${district}&page=${page}&limit=20&search=${searchVal}&pincode=${pin}&regionName=${regionName}`;
      }
    } else {
      url = `users/getUsers/circle?page=${page}&limit=20&`;
    }
  } else {
    if (state || district || searchVal || status || pin || circleName || regionName) {
      if (status === "Active") {
        url = `users/all?state=${state}&district=${district}&isActive=true&page=${page}&limit=20&search=${searchVal}&pincode=${pin}&circleName=${circleName}&regionName=${regionName}`;
      } else if (status === "Inactive") {
        url = `users/all?state=${state}&district=${district}&isActive=false&page=${page}&limit=20&search=${searchVal}&pincode=${pin}&circleName=${circleName}&regionName=${regionName}`;
      } else {
        url = `users/all?state=${state}&district=${district}&page=${page}&limit=20&search=${searchVal}&pincode=${pin}&circleName=${circleName}&regionName=${regionName}`;
      }
    } else {
      url = `users/all?page=${page}&limit=20`;
    }
  }
try {
    let params = {
      page: page,
      api: `${appConfig.ip}/${url}`,
      method: "GET",
      successAction: actionTypes.FETCH_USER_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_USER_FAIL_ACTION,
    };
   yield call(commonFunction, params); 
   
  } catch (error) {}
}

function* fetchUserReport(value) {
  const role = localStorage.getItem("role");
  let searchVal = value?.value?.searchVal || "";
  let state = value?.value?.state || "";
  let district = value?.value?.district || "";
  let status = value?.value?.status || "";
  let pin = value?.value?.pin || '';
  let regionName = value?.value?.regionName || '';
  let circleName = value?.value?.circleName || '';
  let url = "";
  if (role === "regionHead" ) {
    if (state || district || searchVal || status || pin) {
      if (status === "Active") {
        url = `users/getUsers/region?state=${state}&district=${district}&isActive=true&search=${searchVal}&pincode=${pin}`;
      } else if (status === "Inactive") {
        url = `users/getUsers/region?state=${state}&district=${district}&isActive=false&search=${searchVal}&pincode=${pin}`;
      } else {
        url = `users/getUsers/region?state=${state}&district=${district}&search=${searchVal}&pincode=${pin}`;
      }
    } else {
      url = `users/getUsers/region`;
    }
  } else if(role === 'circleHead'){
    if (state || district || searchVal || status || pin || regionName) {
      if (status === "Active") {
        url = `users/getUsers/circle?state=${state}&district=${district}&isActive=true&search=${searchVal}&pincode=${pin}&regionName=${regionName}`;
      } else if (status === "Inactive") {
        url = `users/getUsers/circle?state=${state}&district=${district}&isActive=false&search=${searchVal}&pincode=${pin}&regionName=${regionName}`;
      } else {
        url = `users/getUsers/circle?state=${state}&district=${district}&search=${searchVal}&pincode=${pin}&regionName=${regionName}`;
      }
    } else {
      url = `users/getUsers/circle`;
    }
  } else {
    if (state || district || searchVal || status || pin|| circleName || regionName) {
      if (status === "Active") {
        url = `users/all?state=${state}&district=${district}&isActive=true&search=${searchVal}&pincode=${pin}&circleName=${circleName}&regionName=${regionName}`;
      } else if (status === "Inactive") {
      url = `users/all?state=${state}&district=${district}&isActive=false&search=${searchVal}&pincode=${pin}&circleName=${circleName}&regionName=${regionName}`;
      } else {
        url = `users/all?state=${state}&district=${district}&search=${searchVal}&pincode=${pin}&circleName=${circleName}&regionName=${regionName}`;
      }
    } else {
      url = `users/all?`;
    }
  }
  try {
    let params = {
      api: `${appConfig.ip}/${url}`,
      method: "GET",
      successAction: actionTypes.FETCH_USER_REPORT_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_USER_REPORT_INIT_ACTION,
    };
   yield call(commonFunction, params); 
   
  } catch (error) {}
}

function* fetchFieldUser(value) {
  let role = localStorage.getItem("role");
  let stateFilter = value?.value?.stateFilter || '';
  let districtFilter = value?.value?.districtFilter || '';
  let pin = value?.value?.pin || "";  
  let url = "";
  if (role === "admin") {
    if(stateFilter || districtFilter || pin) {
      url =`users/fieldUsers?isActive=true&state=${stateFilter}&district=${districtFilter}&pincode=${pin}`
    } else {
      url = "users/fieldUsers?isActive=true";
    }
  } else if (role === 'circleHead') {
    if(stateFilter || districtFilter || pin) {
      url =`users/getUsers/circle/fieldUsers?isActive=true&state=${stateFilter}&district=${districtFilter}&pincode=${pin}`
    } else {
      url = "users/getUsers/circle/fieldUsers?isActive=true";
    }
  }else if (role === 'regionHead') {
    if(stateFilter || districtFilter || pin) {
      url =`users/getUsers/region?isActive=true&state=${stateFilter}&district=${districtFilter}&pincode=${pin}`
    } else {
      url = "users/getUsers/region?isActive=true";
    }
  }
  try {
    let params = {
      api: `${appConfig.ip}/${url}`,
      method: "GET",
      successAction: actionTypes.FETCH_FIELDUSER_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_FIELDUSER_FAIL_ACTION,
    };
    yield call(commonFunction, params);
   
  } catch (e) {
  }
}

function* editUser(value) {
  let name =
    value && value.value.value.name
      ? value.value.value.name
      : value.value.item.userName;
  let state =
    value && value.value.value.state
      ? value.value.value.state
      : value.value.item.state;
  let role =
    value && value.value.value.userRole
      ? value.value.value.userRole
      : value.value.item.role;
  let district = "";
  let pin = value?.value?.value?.pincode || [];
  let circle = value?.value?.value?.circleName ;
  let region = value?.value?.value?.regionName;
  
let data = {};
  if (role === "fieldUser") {
    district =
      value && value.value.value.district
        ? value.value.value.district
        : value.value.item.district;
  } else {
    district = "";
  }
  if(role === 'fieldUser') {
    data = {
      userName: name,
      state: state,
      district: district,
      role: role,
      pincode: pin,
      circleName: circle,
      regionName: region 
    }
  } else if(role === 'circleHead'){
    data = {
        userName: name,
        state: state,
        role: role,
        circleName: circle,
    }
  } else if(role === 'regionHead'){
    data = {
      userName: name,
      state: state,
      role: role,
      circleName: circle,
      regionName: region,
    };
  }
  let id = value && value.value.item ? value.value.item._id : "";
  
  try {
    let params = {
      api: `${appConfig.ip}/users/update/${id}`,
      method: "PUT",
      successAction: actionTypes.EDIT_USER_SUCCESS_ACTION,
      failAction: actionTypes.EDIT_USER_FAIL_ACTION,
      body: JSON.stringify(data),
      toastMessage: "User details updated successfully",
      toastErrorMessage: "Something went wrong. Try again",
    };

    yield call(commonFunction, params);
  
  } catch (error) {}
}

function* changeStatus(value) {
  let id = value?.value?._id;
   
  let data = {
    isActive: value.value.isActive === true ? false : true,
  };
  try {
    let params = {
      api: `${appConfig.ip}/users/update/${id}`,
      method: "PUT",
      successAction: actionTypes.CHANGE_STATUS_USER_SUCCESS_ACTION,
      failAction: actionTypes.CHANGE_STATUS_USER_FAIL_ACTION,
      body: JSON.stringify(data),
      toastMessage: "User successfully updated ",
      toastErrorMessage: "Something went wrong. Try again",
    };
    yield call(commonFunction, params);

  } catch (err) {}
}

function chunkArray(array, chunkSize) {
  let chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

function* exportUserData(value) {
  const format = value?.value?.format;
  const userList = value?.value?.userList || [];
  
  const chunkSize = 10000;
  const customerChunks = chunkArray(userList, chunkSize);

  try {
    const allBlobs = []; 

    for (const chunk of customerChunks) {
      const data = { mobileNo: chunk };
      const urls = `/users/export?format=${format}`;

      let params = {
        api: `${appConfig.ip}${urls}`,
        method: "POST",
        successAction: actionTypes.EXPORT_USER_DATA_SUCCESS_ACTION,
        failAction: actionTypes.EXPORT_USER_DATA_FAIL_ACTION,
        body: JSON.stringify(data),
        toastErrorMessage: "Failed to export data",
      };

      const blobResponse = yield call(commonFunction, params);
  
      if (blobResponse instanceof Blob) {
        allBlobs.push(blobResponse); 
      }
    }

    const combinedBlob = new Blob(allBlobs, { type: allBlobs[0].type });

    const url = window.URL.createObjectURL(combinedBlob);
    const a = document.createElement("a");
    a.href = url;

    if (format === "excel") {
      a.download = `User_Report_${Date.now()}.xlsx`;
    } else {
      a.download = `User_Report_${Date.now()}.csv`;
    }
    
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);

    yield toast.success("Successfully exported  data");
    
  } catch (error) {
    yield toast.error("Failed to export data");
  }
}

function* fetchCircleNames(value){
  let url ='/masterdata/postalCode/circle';
try{
  let params = {
    api:`${appConfig.ip}${url}`,
    successAction: actionTypes.FETCH_CIRCLE_NAME_SUCCESS_ACTION,
    failAction: actionTypes.FETCH_CIRCLE_NAME_INIT_ACTION,
    method: 'GET'
  }

  yield call(commonFunction, params)
 
} catch(err){
  console.log('Failed to fetch circle name', err);
}

}

function* fetchRegionNames(value){
  
  let circle = value?.value?.circle || ''
  let url = '';
  if(circle){
    url = `masterdata/postalCode/regions?circle=${circle}`
  } else {
    url = 'masterdata/postalCode/regions'
  }
  // let url = `masterdata/postalCode/regions?circle=${circle}`;
  try{
    let params = {
      api: `${appConfig.ip}/${url}`,
      method: 'GET',
      successAction:actionTypes.FETCH_REGION_NAME_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_REGION_NAME_FAIL_ACTION
    }
    yield call(commonFunction, params)

  } catch(err){
    console.log('Failed to fetch region names', err);
  }

}

function* fetchPincodes(values) {
  let url = '';
  let region = values?.value?.region;
  let circle = values?.value?.circle;

  if(region){
     url = `masterdata/postalCode/pin?region=${region}`;
  } else if(circle) {
      url = `masterdata/postalCode/pin?circle=${circle}`;
  } else {
    url = 'masterdata/postalCode/pin';
  }
  // if(circle){
  //  url = `masterdata/pinCode?circleName=${circle}`
  // } else if(region) {
  //   url = `masterdata/pinCode?regionName=${region}`
  // } else if(circle && region) {
  //   url = `masterdata/pinCode?circleName=${circle}&regionName=${region}`
  // }
  try{
    let params={
      api:`${appConfig.ip}/${url}`,
      method: 'GET',
      successAction:actionTypes.FETCH_PINCODS_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_PINCODS_FAIL_ACTION
    }

    yield call (commonFunction, params)
  }catch (err){
    console.log('Error fetching pincods', err);
  }
}

export function* UserActionWatcher() {
  yield takeEvery(actionTypes.ADD_USER_INIT_ACTION, addUser);
  yield takeEvery(actionTypes.FETCH_FIELDUSER_INIT_ACTION, fetchFieldUser);
  yield takeEvery(actionTypes.FETCH_USER_INIT_ACTION, fetchUser);
  yield takeEvery(actionTypes.EDIT_USER_INIT_ACTION, editUser);
  yield takeEvery(actionTypes.CHANGE_STATUS_USER_INIT_ACTION, changeStatus);
  yield takeEvery(actionTypes.EXPORT_USER_DATA_INIT_ACTION, exportUserData);
  yield takeEvery(actionTypes.FETCH_USER_REPORT_INIT_ACTION, fetchUserReport);
  yield takeEvery(actionTypes.FETCH_CIRCLE_NAME_INIT_ACTION, fetchCircleNames);
  yield takeEvery(actionTypes.FETCH_REGION_NAME_INIT_ACTION, fetchRegionNames);
  yield takeEvery(actionTypes.FETCH_PINCODS_INIT_ACTION, fetchPincodes)
}
