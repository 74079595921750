import { put, takeEvery, call } from "redux-saga/effects";
import "react-toastify/dist/ReactToastify.css";

import {
  FETCH_TOTAL_COUNT_INIT_ACTION,
  FETCH_VERIFIED_COUNT_INIT_ACTION,
  FETCH_ATTEMPTED_COUNT_INIT_ACTION,
  FETCH_PENDING_COUNT_INIT_ACTION,
  FETCH_PENDING_COUNT_SUCCESS_ACTION,
  FETCH_PENDING_COUNT_FAIL_ACTION,
  FETCH_ATTEMPTED_COUNT_SUCCESS_ACTION,
  FETCH_VERIFIED_COUNT_SUCCESS_ACTION,
  FETCH_VERIFIED_COUNT_FAIL_ACTION,
  FETCH_TOTAL_COUNT_SUCCESS_ACTION,
  FETCH_TOTAL_COUNT_FAIL_ACTION,
  FETCH_ATTEMPTED_COUNT_FAIL_ACTION,
  FETCH_ALLOCATED_COUNT_INIT_ACTION,
  FETCH_UNALLOCATED_COUNT_INIT_ACTION,
  FETCH_TOTALUSER_COUNT_INIT_ACTION,
  FETCH_ACTIVEUSER_COUNT_INIT_ACTION,
  FETCH_INACTIVEUSER_COUNT_INIT_ACTION,
  FETCH_ALLOCATED_COUNT_SUCCESS_ACTION,
  FETCH_ALLOCATED_COUNT_FAIL_ACTION,
  FETCH_UNALLOCATED_COUNT_SUCCESS_ACTION,
  FETCH_UNALLOCATED_COUNT_FAIL_ACTION,
  FETCH_TOTALUSER_COUNT_SUCCESS_ACTION,
  FETCH_TOTALUSER_COUNT_FAIL_ACTION,
  FETCH_ACTIVEUSER_COUNT_SUCCESS_ACTION,
  FETCH_ACTIVEUSER_COUNT_FAIL_ACTION,
  FETCH_INACTIVEUSER_COUNT_SUCCESS_ACTION,
  FETCH_INACTIVEUSER_COUNT_FAIL_ACTION,
  FETCH_GRAPH_DATA_INIT_ACTION,
  FETCH_GRAPH_DATA_SUCCESS_ACTION,
} from "./action";
import { push } from "connected-react-router";
import { appConfig } from "../../config";

function* commonFunction(value) {
  const token = localStorage.getItem("userToken");

  try {

    const response = yield fetch(`${value.api}`, {
      method: value.method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });

    if (!response.ok) {
      if (response.status === 401) {
        yield localStorage.removeItem("userToken");
        yield localStorage.removeItem("role");
        yield localStorage.removeItem("userData");
        yield localStorage.removeItem("userFilters");
        yield localStorage.removeItem("partyFilters");
        yield put(push("/login"));
      }
      throw response;
    } else {
      const resJSON = yield response.json();
      return resJSON;
    }
  } catch (error) {
    yield put({ type: `${value.failAction}`, error });
    throw error;
  }
}

function* totalCount() {
  
  const role = localStorage.getItem("role");

  let url = "";
  if (role === "admin") {
    url = "/verification/customersByName?page=1&limit=1";
  } else if (role === 'circleHead'){    
    url = "/verification/getCustomers/circle?page=1&limit=1";
  } else if(role==='regionHead') {
    url = "/verification/getCustomers/region?page=1&limit=1";
  }

  try {
    let params = {
      api: `${appConfig.ip}${url}`,
      method: "GET",
      successAction: FETCH_TOTAL_COUNT_SUCCESS_ACTION,
      failAction: FETCH_TOTAL_COUNT_FAIL_ACTION,
    };

    const res = yield call(commonFunction, params);
      
    if (role === "admin" && res && res.totalCount) {
      yield put({
        type: FETCH_TOTAL_COUNT_SUCCESS_ACTION,
        payload: res.totalCount,
      });
    } else if (res && res.totalCount) {
      yield put({
        type: FETCH_TOTAL_COUNT_SUCCESS_ACTION,
        payload: res.totalCount,
      });
    }
  } catch (err) {
  }
}

function* verifiedCount(item) {
  const role = localStorage.getItem("role");

  let url = "";

  if (role === "admin") {
    url = "/verification/customersByName?page=1&limit=1&isVerified=true";
  } else if(role=== 'circleHead'){
    url = "/verification/getCustomers/circle?isVerified=true&page=1&limit=1";
  } else if(role ==='regionHead'){
    url = "/verification/getCustomers/region?isVerified=true&page=1&limit=1";
  }

  try {
    let params = {
      api: `${appConfig.ip}${url}`,
      method: "GET",
      successAction: FETCH_VERIFIED_COUNT_SUCCESS_ACTION,
      failAction: FETCH_VERIFIED_COUNT_FAIL_ACTION,
    };
    let res = yield call(commonFunction, params);

    if (role === "admin" && res && res.totalCount) {
      yield put({
        type: FETCH_VERIFIED_COUNT_SUCCESS_ACTION,
        payload: res.totalCount,
      });
    } else if ( res && res.totalCount) {
      yield put({
        type: FETCH_VERIFIED_COUNT_SUCCESS_ACTION,
        payload: res.totalCount,
      });
    }
  } catch (err) {}
}

function* attemptedCount(item) {
  const role = localStorage.getItem("role");
  let url = "";

  if (role === "admin") {
    url = "/verification/attemptedCount";
  } else if(role === 'circleHead'){
    url = "/verification/dashboard/attemptedCount/circle";
  } else if(role=== 'regionHead'){
    url = "/verification/dashboard/attemptedCount/region";
  }

  try {
    let params = {
      api: `${appConfig.ip}${url}`,
      method: "GET",
      successAction: FETCH_ATTEMPTED_COUNT_SUCCESS_ACTION,
      failAction: FETCH_ATTEMPTED_COUNT_FAIL_ACTION,
    };
    let res = yield call(commonFunction, params);
    if (res && res.count) {
      yield put({
        type: FETCH_ATTEMPTED_COUNT_SUCCESS_ACTION,
        payload: res.count,
      });
    }
  } catch (err) {}
}

function* pendingCount(item) {
  const role = localStorage.getItem("role");

  let url = "";

  if (role === "admin") {
    url = "/verification/customersByName?page=1&limit=1&isVerified=false";
  } else if(role ==='circleHead') {
    url = "/verification/getCustomers/circle?isVerified=false&page=1&limit=1";
  } else if(role === 'regionHead'){
    url = "/verification/getCustomers/region?isVerified=false&page=1&limit=1";
  }

  try {
    let params = {
      api: `${appConfig.ip}${url}`,
      method: "GET",
      successAction: FETCH_PENDING_COUNT_SUCCESS_ACTION,
      failAction: FETCH_PENDING_COUNT_FAIL_ACTION,
    };
    let res = yield call(commonFunction, params);

    if (role === "admin" && res && res.totalCount) {
      yield put({
        type: FETCH_PENDING_COUNT_SUCCESS_ACTION,
        payload: res.totalCount,
      });
    } else if ( res && res.totalCount) {
      yield put({
        type: FETCH_PENDING_COUNT_SUCCESS_ACTION,
        payload: res.totalCount,
      });
    }
  } catch (err) {}
}

function* fetchAllocatedCount() {
  const role = localStorage.getItem("role");
  let url = "";
  if (role === "admin") {
    url = "/verification/allocated?page=1&limit=1";
  } else if(role === 'circleHead'){
    url ='/verification/allocation/circle?page=1&limit=1'
  }else if(role === 'regionHead') {
    url ='/verification/allocation/region?page=1&limit=1'
  }

  try {
    let params = {
      api: `${appConfig.ip}${url}`,
      method: "GET",
      successAction: FETCH_ALLOCATED_COUNT_SUCCESS_ACTION,
      failAction: FETCH_ALLOCATED_COUNT_FAIL_ACTION,
    };

    const res = yield call(commonFunction, params);
      
    if (role === "admin" && res && res.totalCount) {
      yield put({
        type: FETCH_ALLOCATED_COUNT_SUCCESS_ACTION,
        payload: res.totalCount,
      });
    } else if ( res && res.totalCount) {
      yield put({
        type: FETCH_ALLOCATED_COUNT_SUCCESS_ACTION,
        payload: res.totalCount,
      });
    }
  } catch (err) {
  }
}

function* fetchUnallocatedCount() {
  const role = localStorage.getItem("role");
  let url = "";
  if (role === "admin") {
    url = "/verification/nonAllocated?page=1&limit=1";
  } else if( role=== 'circleHead') {
    url = "/verification/nonAllocation/circle??page=1&limit=1";
  } else if(role === 'regionHead'){
    url = "/verification/nonAllocation/region?page=1&limit=1";
  }
 
  try {
    let params = {
      api: `${appConfig.ip}${url}`,
      method: "GET",
      successAction: FETCH_UNALLOCATED_COUNT_SUCCESS_ACTION,
      failAction: FETCH_UNALLOCATED_COUNT_FAIL_ACTION,
    };

    const res = yield call(commonFunction, params);
      
    if (role === "admin" && res && res.totalCount) {
      yield put({
        type: FETCH_UNALLOCATED_COUNT_SUCCESS_ACTION,
        payload: res.totalCount,
      });
    } else if ( res && res.totalCount) {
      yield put({
        type: FETCH_UNALLOCATED_COUNT_SUCCESS_ACTION,
        payload: res.totalCount,
      });
    }
  } catch (err) {
    // console.error("Error in totalCount saga:", err);
  }
}

function* fetchTotalUserCount() {
  const role = localStorage.getItem("role");
  let url = "";
  if (role === "admin") {
    url = "/users/all";
  } else if(role==='circleHead'){
    url = "/users/getUsers/circle?page=1&limit=1";
  } else if(role === 'regionHead'){
    url = "/users/getUsers/region?page=1&limit=1"
  }

  try {
    let params = {
      api: `${appConfig.ip}${url}`,
      method: "GET",
      successAction: FETCH_TOTALUSER_COUNT_SUCCESS_ACTION,
      failAction: FETCH_TOTALUSER_COUNT_FAIL_ACTION,
    };

    const res = yield call(commonFunction, params);
      
    if (role === "admin" && res && res.totalCount) {
      yield put({
        type: FETCH_TOTALUSER_COUNT_SUCCESS_ACTION,
        payload: res.totalCount,
      });
    } else if ( res && res.totalCount) {
      yield put({
        type: FETCH_TOTALUSER_COUNT_SUCCESS_ACTION,
        payload: res.totalCount,
      });
    }
  } catch (err) {
    // console.error("Error in totalCount saga:", err);
  }
}

function* fetchActiveUserCount() {
  const role = localStorage.getItem("role");
  let url = "";
  if (role === "admin") {
    url = '/users/all?isActive=true';
  } else if(role === 'circleHead') {
    url = "/users/getUsers/circle?page=1&limit=1&isActive=true";
  } else if(role === 'regionHead'){
    url = "/users/getUsers/region?page=1&limit=1&isActive=true";
  }

  try {
    let params = {
      api: `${appConfig.ip}${url}`,
      method: "GET",
      successAction: FETCH_ACTIVEUSER_COUNT_SUCCESS_ACTION,
      failAction: FETCH_ACTIVEUSER_COUNT_FAIL_ACTION,
    };

    const res = yield call(commonFunction, params);
      
    if (role === "admin" && res && res.totalCount) {
      yield put({
        type: FETCH_ACTIVEUSER_COUNT_SUCCESS_ACTION,
        payload: res.totalCount,
      });
    } else if (res && res.totalCount) {
      yield put({
        type: FETCH_ACTIVEUSER_COUNT_SUCCESS_ACTION,
        payload: res.totalCount,
      });
    }
  } catch (err) {
  }
}

function* fetchInactiveUserCount() {
  const role = localStorage.getItem("role");
  let url = "";
  if (role === "admin") {
    url = '/users/all?isActive=false';
  } else if(role === 'cicleHead') {
    url = "/users/getUsers/circle?page=1&limit=1&isActive=false";
  } else if(role === 'regionHead'){
    url = "/users/getUsers/region?page=1&limit=1&isActive=false";
  }

  try {
    let params = {
      api: `${appConfig.ip}${url}`,
      method: "GET",
      successAction: FETCH_INACTIVEUSER_COUNT_SUCCESS_ACTION,
      failAction: FETCH_INACTIVEUSER_COUNT_FAIL_ACTION,
    };

    const res = yield call(commonFunction, params);
    if (role === "admin" && res && res.totalCount) {
      yield put({
        type: FETCH_INACTIVEUSER_COUNT_SUCCESS_ACTION,
        payload: res.totalCount,
      });
    } else if (role === "regionHead" && res && res.totalCount) {
      yield put({
        type: FETCH_INACTIVEUSER_COUNT_SUCCESS_ACTION,
        payload: res.totalCount,
      });
    }
  } catch (err) {
    // console.error("Error in totalCount saga:", err);
  }
}

function* getGraphData() {
  const role = localStorage.getItem("role");
  let url = "";
  if (role === "admin") {
    url = '/verification/monthly/count';
  } else if(role === 'circleHead') {
    url = "/verification/monthly/count/circle";
  } else if(role === 'regionHead'){
    url = '/verification/monthly/count/region'
  }

  try {
    let params = {
      api: `${appConfig.ip}${url}`,
      method: "GET",
      successAction: FETCH_INACTIVEUSER_COUNT_SUCCESS_ACTION,
      failAction: FETCH_INACTIVEUSER_COUNT_FAIL_ACTION,
    };

    const res = yield call(commonFunction, params);
    
    if (res) {
      yield put({
        type: FETCH_GRAPH_DATA_SUCCESS_ACTION,
        payload: res,
      });
    } 
  } catch (err) {
  }
}


export function* dashboardActionWatcher() {
  yield takeEvery(FETCH_TOTAL_COUNT_INIT_ACTION, totalCount);
  yield takeEvery(FETCH_VERIFIED_COUNT_INIT_ACTION, verifiedCount);
  yield takeEvery(FETCH_ATTEMPTED_COUNT_INIT_ACTION, attemptedCount);
  yield takeEvery(FETCH_PENDING_COUNT_INIT_ACTION, pendingCount);
  yield takeEvery(FETCH_ALLOCATED_COUNT_INIT_ACTION, fetchAllocatedCount);
  yield takeEvery(FETCH_UNALLOCATED_COUNT_INIT_ACTION, fetchUnallocatedCount);
  yield takeEvery(FETCH_TOTALUSER_COUNT_INIT_ACTION, fetchTotalUserCount);
  yield takeEvery(FETCH_ACTIVEUSER_COUNT_INIT_ACTION, fetchActiveUserCount);
  yield takeEvery(FETCH_INACTIVEUSER_COUNT_INIT_ACTION, fetchInactiveUserCount)
  yield takeEvery(FETCH_GRAPH_DATA_INIT_ACTION, getGraphData)
  
}
